import React, { useState } from "react";
import toast from "react-hot-toast";

const FromThree = ({ setfirst, setdata }) => {
  const [shareholders, setShareholders] = useState([{}]);
  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const [addanother, setaddanother] = useState(true);
  const [show, setshow] = useState(false);
  const handleshowAddbutton = (e) => {
    if (e.target.value === "yes") {
      setshow(true);
    } else {
      setshow(false);
    }
  };
  const handleChange = (e, shareholderIndex, field) => {
    const newShareholders = [...shareholders];
    newShareholders[shareholderIndex] = {
      ...newShareholders[shareholderIndex],
      [field]: e.target.value,
    };
    setShareholders(newShareholders);
  };
  const handleFileChange = (e, shareholderIndex, field) => {
    const newShareholders = [...shareholders];
    newShareholders[shareholderIndex] = {
      ...newShareholders[shareholderIndex],
      [field]: e.target.files[0],
    };
    setShareholders(newShareholders);
    setaddanother(false);
  };
  const addShareholder = () => {
    setShareholders([...shareholders, {}]);
    setshow(false);
    setaddanother(true);
  };
  const removeShareholder = (index) => {
    setShareholders((prevShareholders) =>
      prevShareholders.filter((_, i) => i !== index)
    );
    setaddanother(false);
  };

  const handlesubmit = () => {
   
    if (
      !shareholders[0]?.First_Name ||
      !shareholders[0]?.Last_Name ||
      !shareholders[0]?.Street_Address ||
      !shareholders[0]?.street_address_line_2||
      !shareholders[0]?.city ||
      !shareholders[0]?.country||
      !shareholders[0]?.zipcode||
      !shareholders[0]?.Ownership||
      !shareholders[0]?.dateofbirth||
      !shareholders[0]?.SocialSecurityNumber ||
      !shareholders[0]?.IdentificationType ||
      !shareholders[0]?.Identification_Number ||
      !shareholders[0]?.identification_expiration_date ||
      !shareholders[0]?.Front_Image ||
      !shareholders[0]?.Back_Image 
    ){
      toast.error("Please Fill All Required field");
      return;
    }
    setdata((pre) => {
      return {
        ...pre,
        shareholders,
      };
    });
    setfirst(4);
  };
  return (
    <div className=" js-active " data-animation="slideVert">
      <div className="inner pb-100">
        {shareholders?.map((data, index) => {
          return (
            <>
              <div className="wizard-content-item text-center pt-4 pb-4">
                <h2>
                  Shareholder / Owner{" "}
                  {index > 0 && (
                    <button
                      className="btn btn-danger float-right"
                      onClick={() => removeShareholder(index)}
                    >
                      Remove
                    </button>
                  )}
                </h2>
              </div>
              <div className="wizard-content-form">
                <div className="wizard-form-field">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="First_Name"
                          placeholder="First Name"
                          onChange={(e) => handleChange(e, index, "First_Name")}
                        />
                        <label>First Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="Last_Name"
                          placeholder="Last Name"
                          onChange={(e) => handleChange(e, index, "Last_Name")}
                        />
                        <label>Last Name</label>
                      </div>
                    </div>
                    <div className="col-12  pt-4 pb-4">
                      <h3>Shareholder 1 Address*</h3>
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="text"
                          className="form-control"
                          name="Street_Address"
                          onChange={(e) =>
                            handleChange(e, index, "Street_Address")
                          }
                          placeholder="Street Address"
                        />
                        <label>Street Address</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="text"
                          className="form-control"
                          name="street_address_line_2"
                          onChange={(e) =>
                            handleChange(e, index, "street_address_line_2")
                          }
                          placeholder=" Street Address Line 2"
                        />
                        <label>Street Address Line 2</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          onChange={(e) => handleChange(e, index, "city")}
                          placeholder="City"
                        />
                        <label>City</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                        <select
                          id="country"
                          name="country"
                          onChange={(e) => handleChange(e, index, "country")}
                          className="form-control"
                        >
                          <option selected disabled value="">
                            State
                          </option>
                          {usStates.map((data, index) => {
                            return (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="number"
                          onChange={(e) => handleChange(e, index, "zipcode")}
                          className="form-control"
                          name="zipcode"
                          placeholder="Zip/Postal Code"
                        />
                        <label>Zip/Postal Code</label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 pt-4 pb-4">
                      <h2>Shareholder Other Details</h2>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="number"
                          onChange={(e) => handleChange(e, index, "Ownership")}
                          className="form-control"
                          name="Ownership"
                          placeholder="Ownership"
                        />
                        <label>Ownership</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="date"
                          onChange={(e) =>
                            handleChange(e, index, "dateofbirth")
                          }
                          className="form-control"
                          name="Date of Birth*"
                          placeholder="Date of Birth*"
                        />

                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="SocialSecurityNumber"
                          onChange={(e) =>
                            handleChange(e, index, "SocialSecurityNumber")
                          }
                          placeholder="Social Security Number*"
                        />
                        <label>Social Security Number*</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pt-4 pb-4">
                      <h2>Identification Type*</h2>
                    </div>
                    <div className="col-12">
                      <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                        <select
                          id="country"
                          name="country"
                          onChange={(e) =>
                            handleChange(e, index, "IdentificationType")
                          }
                          className="form-control"
                        >
                          <option selected disabled value="">
                            Identification Type
                          </option>
                          <option>Drivers License</option>
                          <option>Identification Card</option>
                          <option>Passport</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          name="Identification_Number"
                          onChange={(e) =>
                            handleChange(e, index, "Identification_Number")
                          }
                          className="form-control"
                          placeholder="Identification Number*"
                        />
                        <label>Identification Numbers</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="date"
                          name="identification_expiration_date"
                          onChange={(e) =>
                            handleChange(
                              e,
                              index,
                              "identification_expiration_date"
                            )
                          }
                          className="form-control"
                          placeholder="Identification Number*"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                        <select
                          id="country"
                          name="country"
                          onChange={(e) =>
                            handleChange(e, index, "State_of_Issuance")
                          }
                          className="form-control"
                        >
                          <option selected disabled value="">
                            State of Issuance*
                          </option>
                          {usStates.map((data, index) => {
                            return (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                      <h5>Front of Identification*</h5>
                        <input
                          type="file"
                          style={{height:'50px'}}
                          onChange={(e) =>
                            handleFileChange(e, index, "Front_Image")
                          }
                          className="form-control mt-2"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                      <h5>Back of Identification*</h5>
                        <input
                          type="file"
                          style={{height:'50px'}}
                          onChange={(e) =>
                            handleFileChange(e, index, "Back_Image")
                          }
                          className="form-control mt-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="badge-selection">
                  <h3>
                    Do you have additional shareholders / owners with 25% or
                    greater ownership?*
                  </h3>

                  <label>
                    <input
                      onChange={handleshowAddbutton}
                      value="yes"
                      type="radio"
                      className="disable"
                      disabled={addanother}
                      name="addbutton"
                    />
                    <span className="checkmark">Yes</span>
                  </label>
                  <label>
                    <input
                      onChange={handleshowAddbutton}
                      value="no"
                      disabled={addanother}
                      type="radio"
                      name="addbutton"
                    />
                    <span className="checkmark">No</span>
                  </label>
                </div>
              </div>
            </>
          );
        })}

        <div className="mt-4">
          <div className="actions">
            <ul>
              {show && shareholders.length !== 4 && (
                <li onClick={addShareholder}>
                  <span className="js-btn-prev" title="new">
                    <i className="fa fa-arrow-left" />
                    new add
                  </span>
                </li>
              )}

              <li onClick={() => setfirst(2)}>
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left" /> BACK
                </span>
              </li>
              <li onClick={handlesubmit}>
                <span className="js-btn-next" title="NEXT">
                  NEXT <i className="fa fa-arrow-right" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FromThree;
