import React, { useState } from "react";
import toast from "react-hot-toast";

const Fromtwo = ({ setfirst, setdata }) => {
  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const [secondfromdata, setsecondfromdata] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setsecondfromdata((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };
  const handleSubmit = () => {
    const {
      business_name,
      ein_number,
      state_of_formation,
      physical_street_address,
      physical_street_address_line_2,
      physical_city,
      physical_state,
      physical_zip_code
    } = secondfromdata;
    if (
      !business_name ||
      !ein_number ||
      !state_of_formation ||
      !physical_street_address||
      !physical_street_address_line_2 ||
      !physical_city||
      !physical_state||
      !physical_zip_code
    ){
      toast.error("Please Fill All Required field");
      return;
    }
    setdata((pre) => {
      return {
        ...pre,
        ...secondfromdata
      };
    });
    setfirst(3);
  };

  return (
    <div className=" js-active " data-animation="slideVert">
      <div className="inner pb-100">
        <div className="wizard-content-item text-center pt-4 pb-4">
          <h2>Business Information</h2>
        </div>
        <div className="wizard-content-form">
          <div className="wizard-form-field">
            <div className="wizard-form-input position-relative form-group has-float-label">
              <i
                data-toggle="tooltip"
                data-placement="bottom"
                title="If you want your invoice address to a compnay. Leave blank to use full name"
                className="fa fa-info"
              />
              <input
                type="text"
                name="business_name"
                onChange={handleChange}
                className="form-control"
                placeholder="Business Name*"
              />
              <label>Business Name*</label>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <i
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="If you want your invoice address to a compnay. Leave blank to use full name"
                    className="fa fa-info"
                  />
                  <input
                    type="number"
                    className="form-control"
                    onChange={handleChange}
                    name="ein_number"
                    placeholder="EIN Number"
                  />
                  <label>EIN Number</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                  <select
                    id="country"
                    onChange={handleChange}
                    name="state_of_formation"
                    className="form-control"
                  >
                    <option selected disabled value="">
                      State of Formation*
                    </option>
                    {usStates.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12   pt-4 pb-4">
                <h3>Business Physical Address*</h3>
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    name="physical_street_address"
                    onChange={handleChange}
                    placeholder="Street Address"
                  />
                  <label>Street Address</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    name="physical_street_address_line_2"
                    onChange={handleChange}
                    placeholder=" Street Address Line 2"
                  />
                  <label>Street Address Line 2</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    name="physical_city"
                    onChange={handleChange}
                    placeholder="City"
                  />
                  <label>City</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                  <select
                    id="country"
                    name="physical_state"
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option selected disabled value="">
                      State
                    </option>
                    {usStates?.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="number"
                    className="form-control"
                    name="physical_zip_code"
                    onChange={handleChange}
                    placeholder="Zip/Postal Code"
                  />
                  <label>Zip/Postal Code</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12   pt-4 pb-4">
                <h3>
                  Business Mailing Address (If Different Than Physical Address)
                </h3>
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    name="mailing_street_address"
                    placeholder="Street Address"
                  />
                  <label>Street Address</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    name="mailing_street_address_line_2"
                    placeholder=" Street Address Line 2"
                  />
                  <label>Street Address Line 2</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    name="mailing_city"
                    placeholder="City"
                  />
                  <label>City</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                  <select
                    id="country"
                    onChange={handleChange}
                    name="mailing_state"
                    className="form-control"
                  >
                    <option selected disabled value="">
                      State
                    </option>
                    {usStates.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="number"
                    className="form-control"
                    name="mailing_zip_code"
                    onChange={handleChange}
                    placeholder="Zip/Postal Code"
                  />
                  <label>Zip/Postal Code</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="actions">
            <ul>
              <li onClick={() => setfirst(1)}>
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left" /> BACK
                </span>
              </li>
              <li onClick={handleSubmit}>
                <span className="js-btn-next" title="NEXT">
                  NEXT <i className="fa fa-arrow-right" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fromtwo;
