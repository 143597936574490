import React from "react";
import { Link } from "react-router-dom";
import logo from "./image/logo.png";
import "./App.css";
const Landingpage = () => {
  return (
    <div className="container-fluid" style={{ backgroundColor: "#F1F9FE" }}>
      <div className="container py-4">
        <div className="row">
          <div className="col-md-4">
            <img src={logo} width={"200px"} alt="logo" />
          </div>
          <div className="col-md-8 mt-5">
            <h1>Department of Records Management Business Filing Division</h1>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <h5>Official Online Filing Portal</h5>
            <h3>Mandatory Ownership Reporting</h3>
            <p className="mt-3">
              The use of this portal is restricted to business entities or
              individuals representing business entities. All information
              provided must be true and accurate to the best of your knowledge.
              Knowingly entering false or misleading information will result in
              a permanent block from the use of the portal, and may lead to
              fines or penalties.
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <div className="text-center my-5">
              <span className="text-secondary ">STEP</span>
              <h1 className="text-capitalize font-weight-bold">
                PLEASE <span style={{ color: "#9B5DE5" }}>READ</span>
              </h1>
            </div>
          </div>
          <div className="col-12  mx-auto">
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex my-4 align-items-start">
                  <div className="mr-3 text-center mt-2">
                    <div
                      className="p-4 rounded-circle text-white font-weight-bold d-flex align-items-center justify-content-center"
                      style={{
                        height: 40,
                        width: 40,
                        backgroundColor: "#9B5DE5",
                      }}
                    >
                      1
                    </div>
                    <span className="text-secondary">STEP</span>
                  </div>
                  <div className="rounded bg-transparent  p-4">
                    <h5 className="mb-3" style={{ fontWeight: 600 }}>
                      Are You Required to File
                    </h5>
                    <ul className="mt-1">
                      <li>
                        All US businesses must file, except the exemptions
                        listed below
                      </li>
                      <li>501(c)3 Non Profits are exempt</li>
                      <li>
                        Business that have stopped operations, and dissolved
                        their business with their state of formation are exempt.
                      </li>
                      <li>
                        You must file even if there are no changes to your
                        ownership
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6"
                style={{
                  boxShadow: "0px 5px 62px 0px rgba(0, 0, 0, 0.19)",
                  borderRadius: "20px",
                }}
              >
                <div className="d-flex my-4 align-items-start">
                  <div className="mr-3 text-center mt-2">
                    <div
                      className="p-4 rounded-circle text-white font-weight-bold d-flex align-items-center justify-content-center"
                      style={{
                        height: 40,
                        width: 40,
                        backgroundColor: "#9B5DE5",
                      }}
                    >
                      2
                    </div>
                    <span className="text-secondary">STEP</span>
                  </div>
                  <div className="rounded bg-transparent  p-4">
                    <h5 className="mb-3" style={{ fontWeight: 600 }}>
                      Understand the Online Application
                    </h5>
                    <ul>
                      <li>
                        You must complete this application in one session, as
                        you will not be able to save and return at a later time.
                      </li>
                      <li>
                        Your session will expire after 15 minutes of inactivity,
                        and you will need to start over.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6"
                style={{
                  boxShadow: "0px 5px 62px 0px rgba(0, 0, 0, 0.19)",
                  borderRadius: "20px",
                }}
              >
                <div className="d-flex my-4 align-items-start">
                  <div className="mr-3 text-center mt-2">
                    <div
                      className="p-4 rounded-circle text-white font-weight-bold d-flex align-items-center justify-content-center"
                      style={{
                        height: 40,
                        width: 40,
                        backgroundColor: "#9B5DE5",
                      }}
                    >
                      3
                    </div>
                    <span className="text-secondary">STEP</span>
                  </div>
                  <div className="rounded bg-transparent  p-4">
                    <h5 className="mb-3" style={{ fontWeight: 600 }}>
                      What You Will Need (Have Everything Ready Before Starting)
                    </h5>
                    <ul>
                      <li>
                        You must report up to date information for all
                        owners/shareholders with 25% or greater ownership.
                      </li>
                      <li>Company Name</li>
                      <li>
                        Company Employment Identification Number (EIN) - If you
                        do not have an EIN assigned to your company you may use
                        the owners Social Security Number
                      </li>
                      <li>Owner's Full Name</li>
                      <li>Address</li>
                      <li>Phone Number</li>
                      <li>Social Security Number</li>
                      <li>Identification</li>
                      <li>Upload Photo of Identification</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex my-4 align-items-start">
                  <div className="mr-3 text-center mt-2">
                    <div
                      className="p-4 rounded-circle text-white font-weight-bold d-flex align-items-center justify-content-center"
                      style={{
                        height: 40,
                        width: 40,
                        backgroundColor: "#9B5DE5",
                      }}
                    >
                      4
                    </div>
                    <span className="text-secondary">STEP</span>
                  </div>
                  <div className="rounded bg-transparent  p-4">
                    <h5 className="mb-3" style={{ fontWeight: 600 }}>
                      Submit Your Application
                    </h5>
                    <ul>
                      <li>Sign your filing and submit</li>
                      <li>We will contact you if any information is missing</li>
                    </ul>
                    <Link
                      to={"/from"}
                      className=" mx-auto custom-btn mt-5"
                      style={{ color: "white", borderRadius: "10px" }}
                    >
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
