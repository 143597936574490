import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import SignatureCanvas from "react-signature-canvas";
const FormOne = ({ setfirst, setdata }) => {
  const signatureRef = useRef();
  const [firstfromdata, setfirstfromdata] = useState({});
  const [signature, setsignature] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setfirstfromdata((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    const signatureData = signatureRef.current.toDataURL();
    setsignature(signatureData);
  };
  const handleSubmit = () => {
    const {
      submitter_first_name,
      submitter_last_name,
      submitter_business_name,
      submitter_email,
      submitter_role
    } = firstfromdata;
    if (
      !submitter_first_name ||
      !submitter_last_name ||
      !submitter_business_name ||
      !submitter_email||
      !submitter_role ||
      !signature
    ) {
      toast.error("Please Fill All Required field");
      return;
    }
    setfirst(2);
    setdata({
      ...firstfromdata,
      signature: signature,
    });
  };
  return (
    <div className=" js-active " data-animation="slideVert">
      <div className="inner pb-100">
        <div className="wizard-content-item text-center pt-4 pb-4">
          <h2>Submitter Information</h2>
        </div>
        <div className="wizard-content-form">
          <div className="wizard-form-field">
            <div className="wizard-form-input position-relative form-group has-float-label">
              <input
                type="text"
                name="notice_id"
                className="form-control"
                onChange={handleChange}
                placeholder="Notice ID"
              />
              <label>
                Notice ID # (Leave Blank If You Didn’t Receive A Notice)
              </label>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <i
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="If you want your invoice address to a compnay. Leave blank to use full name"
                    className="fa fa-info"
                  />
                  <input
                    type="text"
                    className="form-control"
                    name="submitter_first_name"
                    value={firstfromdata?.submitter_first_name}
                    placeholder="First Name"
                    onChange={handleChange}
                  />
                  <label>First Name</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <i
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="If you want your invoice address to a compnay. Leave blank to use full name"
                    className="fa fa-info"
                  />
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={firstfromdata?.submitter_last_name}
                    name="submitter_last_name"
                    placeholder="Last Name"
                  />
                  <label>Last Name</label>
                </div>
              </div>
            </div>
            <div className="wizard-form-input position-relative form-group has-float-label">
              <i
                data-toggle="tooltip"
                data-placement="bottom"
                title="If you want your invoice address to a compnay. Leave blank to use full name"
                className="fa fa-info"
              />
              <input
                type="text"
                className="form-control"
                name="submitter_business_name"
                value={firstfromdata?.submitter_business_name}
                onChange={handleChange}
                placeholder="Business Name"
              />
              <label>Business Name</label>
            </div>
            <div className="wizard-form-input position-relative form-group has-float-label">
              <i
                data-toggle="tooltip"
                data-placement="bottom"
                title="If you want your invoice address to a compnay. Leave blank to use full name"
                className="fa fa-info"
              />
              <input
                type="email"
                className="form-control"
                name="submitter_email"
                value={firstfromdata?.submitter_email}
                onChange={handleChange}
                placeholder="Email Address"
              />
              <label>Email Address</label>
            </div>
          </div>
          <div className="badge-selection">
            <h3>Submitter Role*</h3>
            <label>
              <input
                onChange={handleChange}
                value="Owner"
                type="radio"
                name="submitter_role"
              />
              <span className="checkmark">Owner</span>
            </label>
            <label>
              <input
                onChange={handleChange}
                value="Officer"
                type="radio"
                name="submitter_role"
              />
              <span className="checkmark">Officer</span>
            </label>
            <label>
              <input
                onChange={handleChange}
                value="Paid-Preparer"
                type="radio"
                name="submitter_role"
              />
              <span className="checkmark">Paid Preparer</span>
            </label>
          </div>
          <div className="badge-selection mt-3">
            <h3>
              Signature* (By signing you are confirming that you are authorized
              by "the Company" to act on its behalf.)*
            </h3>
            <div style={{ width: "500px", border: "3px solid black" }}>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: "500px",
                  border: "2px solid black !important",
                  height: 200,

                  className: "signature-canvas",
                }}
                ref={signatureRef}
              />

              <div className="d-flex justify-content-around">
                <button className="custom-btn" onClick={clearSignature}>
                  Clear Signature
                </button>
                <button className="custom-btn" onClick={saveSignature}>
                  Save Signature
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="actions">
            <ul>
              <li onClick={handleSubmit}>
                <span className="js-btn-next" title="NEXT">
                  NEXT <i className="fa fa-arrow-right" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormOne;
