import React, { useState } from "react";
import FormOne from "./FormOne";
import Fromtwo from "./Fromtwo";
import FromThree from "./FromThree";
import Fromfour from "./Fromfour";
import { Toaster } from "react-hot-toast";

const From = () => {
  const [first, setfirst] = useState(1);
  const [data, setdata] = useState({});
  console.log(data, "data");
  return (
    <>
        <Toaster position="top-right" reverseOrder={false} />
      <div className="wrapper clearfix">
        <div className="multisteps-form">
          <div className="row">
            <div className="col-12 col-lg-12 m-auto">
              <div className="multisteps-form__form clearfix">
                {first === 1 && (
                  <FormOne setdata={setdata} setfirst={setfirst} />
                )}
                {first === 2 && (
                  <Fromtwo setdata={setdata} setfirst={setfirst} />
                )}
                {first === 3 && (
                  <FromThree setdata={setdata} setfirst={setfirst} />
                )}
                {first === 4 && (
                  <Fromfour data={data} setdata={setdata} setfirst={setfirst} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default From;
