/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Fromfour = ({ setfirst, setdata, data }) => {
  const signatureRef = useRef();
  const [fourForm, setfourfromdata] = useState({});
  const [segnatoure, setsegnatoure] = useState("");
  const Navigate = useNavigate();
  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    const signatureData = signatureRef.current.toDataURL();
    setsegnatoure(signatureData);
    // You can now store `signatureData` in your state, send it to a server, or handle it as needed.
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setfourfromdata((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };
  const handleSubmit = async () => {
    const { filling_fee_first_name, filling_fee_last_name, filling_fee_email } =
      fourForm;
    if (
      !filling_fee_first_name ||
      !filling_fee_last_name ||
      !filling_fee_email ||
      !segnatoure
    ) {
      toast.error("Please Fill All Required field");
      return;
    }

    const formDataa = new FormData();

    formDataa.append("notice_id", data?.notice_id);
    formDataa.append("submitter_business_name", data?.submitter_business_name);
    // formDataa.append("business_last_name", data?.business_last_name);
    formDataa.append("submitter_first_name", data?.submitter_first_name);
    formDataa.append("submitter_last_name", data?.submitter_last_name);
    formDataa.append("submitter_email", data?.submitter_email);
    formDataa.append("submitter_role", data?.submitter_role);
    formDataa.append("signature", data?.signature);
    formDataa.append("business_name", data?.business_name);
    formDataa.append(
      "filling_fee_first_name",
      fourForm?.filling_fee_first_name
    );
    formDataa.append("filling_fee_last_name", fourForm?.filling_fee_last_name);
    formDataa.append("filling_fee_email", fourForm?.filling_fee_email);
    formDataa.append("filling_fee_signature", segnatoure);
    formDataa.append("ein_number", data?.ein_number);
    formDataa.append("state_of_formation", data?.state_of_formation);
    formDataa.append("physical_street_address", data?.physical_street_address);
    formDataa.append(
      "physical_street_address_line_2",
      data?.physical_street_address_line_2
    );
    formDataa.append("physical_city", data?.physical_city);
    formDataa.append("physical_state", data?.physical_state);
    formDataa.append("physical_zip_code", data?.physical_zip_code);
    formDataa.append("mailing_street_address", data?.mailing_street_address);
    formDataa.append(
      "mailing_street_address_line_2",
      data?.mailing_street_address_line_2
    );
    formDataa.append("mailing_city", data?.mailing_city);
    formDataa.append("mailing_state", data?.mailing_state);
    formDataa.append("mailing_zip_code", data?.mailing_zip_code);
    {
      data?.shareholders?.map((data, index) => {
        formDataa.append(
          `shareholder[${index}][shareholder_first_name]`,
          data?.First_Name
        );
        formDataa.append(
          `shareholder[${index}][shareholder_last_name]`,
          data?.Last_Name
        );
        formDataa.append(
          `shareholder[${index}][shareholder_street_address]`,
          data?.Street_Address
        );
        formDataa.append(
          `shareholder[${index}][shareholder_street_address_line_2]`,
          data?.street_address_line_2
        );
        formDataa.append(`shareholder[${index}][shareholder_city]`, data?.city);
        formDataa.append(
          `shareholder[${index}][shareholder_state]`,
          data?.country
        );
        formDataa.append(
          `shareholder[${index}][shareholder_zip_code]`,
          data?.zipcode
        );
        formDataa.append(
          `shareholder[${index}][social_security_number]`,
          data?.SocialSecurityNumber
        );
        formDataa.append(
          `shareholder[${index}][ownership_percentage]`,
          data?.Ownership
        );
        formDataa.append(
          `shareholder[${index}][identification_type]`,
          data?.IdentificationType
        );
        formDataa.append(
          `shareholder[${index}][identification_number]`,
          data?.Identification_Number
        );
        formDataa.append(
          `shareholder[${index}][date_of_birth]`,
          data?.dateofbirth
        );
        formDataa.append(
          `shareholder[${index}][social_security_number]`,
          data?.SocialSecurityNumber
        );
        formDataa.append(
          `shareholder[${index}][identification_expiration_date]`,
          data?.identification_expiration_date
        );
        formDataa.append(
          `shareholder[${index}][state_of_issuance]`,
          data?.State_of_Issuance
        );
        formDataa.append(
          `shareholder[${index}][front_image]`,
          data?.Front_Image
        );
        formDataa.append(`shareholder[${index}][back_image]`, data?.Back_Image);
      });
    }

    await axios
      .post("http://filingdivision.dev-hi.xyz/api/contact", formDataa)
      .then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message);
          Navigate("/");
        }
        if (res.data.status === false) {
          toast.error(res.data.message);
        }
      })
      .catch((errors) => {
        toast.error(errors.message);
        console.log(errors.errors, "err");
      });
  };

  return (
    <>
      <div className=" js-active " data-animation="slideVert">
        <div className="inner pb-100">
          <div className="wizard-content-item text-center pt-4 pb-4">
            <h2>Signature and Filing Fee</h2>
          </div>
          <div className="wizard-content-form">
            <div className="wizard-form-field">
              <div className="row">
                <div className="col-md-6">
                  <div className="wizard-form-input position-relative form-group has-float-label">
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="If you want your invoice address to a compnay. Leave blank to use full name"
                      className="fa fa-info"
                    />
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="filling_fee_first_name"
                      placeholder="First Name"
                    />
                    <label>First Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="wizard-form-input position-relative form-group has-float-label">
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="If you want your invoice address to a compnay. Leave blank to use full name"
                      className="fa fa-info"
                    />
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="filling_fee_last_name"
                      placeholder="First Name"
                    />
                    <label>Last Name</label>
                  </div>
                </div>
              </div>
              <div className="wizard-form-input position-relative form-group has-float-label">
                <i
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="If you want your invoice address to a compnay. Leave blank to use full name"
                  className="fa fa-info"
                />
                <input
                  type=""
                  onChange={handleChange}
                  name="filling_fee_email"
                  className="form-control"
                  placeholder="Email"
                />
                <label>Email*</label>
              </div>
            </div>
            <div className="badge-selection">
              <h3>
                Signature* (By signing you confirm that all information in this
                filing is correct and complete to the best of your knowledge.
                You understand that by submitting incomplete, false or deceptive
                information you may be face penalties.)*
              </h3>
              <div className="border" style={{ width: "500px" }}>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    width: "500px",
                    border: "2px solid black !important",
                    height: 200,

                    className: "signature-canvas",
                  }}
                  ref={signatureRef}
                />

                <div className="d-flex justify-content-around">
                  <button className="custom-btn" onClick={clearSignature}>
                    Clear Signature
                  </button>
                  <button className="custom-btn" onClick={saveSignature}>
                    Save Signature
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="actions">
              <ul>
                <li onClick={() => setfirst(3)}>
                  <span className="js-btn-prev" title="BACK">
                    <i className="fa fa-arrow-left" /> BACK
                  </span>
                </li>
                <li>
                  <span
                    onClick={handleSubmit}
                    className="js-btn-next"
                    title="NEXT"
                  >
                    Submit <i className="fa fa-arrow-right" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fromfour;
